$primaryColor: #595959;
$secondaryColor: #7a7a7a;
$thirdColor: #f39c12;
$fourthColor: #81A5BC;
$fifthColor: #c0392b;
$sixthColor: #92B692;
$blackColor: rgba(23,23,23,0.85);
$blackColorSecondary: rgba(23,23,23,0.85);
$blackDisabled: rgba(54,54,54,0.73);
$blackHint: rgba(54,54,54,0.38);
$blackColorThird: #b7b7b7;
$grayColor: #F8F9FB;
$whiteColor: #EAEAEA;
$whiteTrueColor: #fff;

$fontFamily:  "Lato", sans-serif;

$errorColor: #d32f2f;
$successColor: #598f36;
$waitingColor: $thirdColor;

:export {
  primaryColor:  $primaryColor;
  secondaryColor:  $secondaryColor;
  thirdColor: $thirdColor;
  fourthColor: $fourthColor;
  fifthColor: $fifthColor;
  sixthColor: $sixthColor;

  blackColor: $blackColor;
  blackColorSecondary: $blackColorSecondary;
  blackColorThird: $blackColorThird;
  blackDisabled: $blackDisabled;
  blackHint: $blackHint;

  grayColor: $grayColor;

  whiteColor: $whiteColor;
  whiteTrueColor: $whiteTrueColor;

  errorColor: $errorColor;
  successColor: $successColor;
  waitingColor: $waitingColor;

  fontFamily: $fontFamily;
}