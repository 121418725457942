html, body {
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Lato", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f5f5f5;
  box-sizing: border-box;
}

*, *:before, *:after {
  font-family: inherit;
  box-sizing: inherit;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  outline: none;
  list-style: none;
  text-decoration: none;
}

/* Custom styles */

.leaflet-bar {
  box-shadow: 0 1px 3px rgba(150, 150, 150, 0.12), 0 1px 2px rgba(125, 125, 125, 0.24);
}

.leaflet-bar a,
.leaflet-bar a:hover {
  width: 32px;
  height: 32px;
  line-height: 28px;
  color: #777777;
  font-size: 20px;
}

.leaflet-popup-content-wrapper {
  border-radius: 10px;
  border-top: 2px solid #1E74BB;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 1px -1px, rgba(0, 0, 0, 0.08) 0px 1px 3px 0px;
}

.leaflet-popup-tip {
  box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 1px -1px, rgba(0, 0, 0, 0.08) 0px 1px 3px 0px;
}

.leaflet-container a.leaflet-popup-close-button {
  top: 10px;
  right: 10px;
}

.leaflet-right .leaflet-control-zoom.leaflet-bar.leaflet-control {
  position: relative;
  bottom: 114px;
  right: 6px;
}

@media screen and (max-width: 768px) {
  .leaflet-right .leaflet-control-zoom.leaflet-bar.leaflet-control {
    right: 0;
  }
}

@media screen and (max-width: 1023px) {
  .leaflet-right .leaflet-control-zoom.leaflet-bar.leaflet-control {
    bottom: 72px;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 1px -1px, rgba(0, 0, 0, 0.08) 0px 1px 3px 0px;
  }

  .leaflet-touch .leaflet-control-layers, .leaflet-touch .leaflet-bar {
    border: none;
  }

  .leaflet-touch .leaflet-bar a:first-child {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  .leaflet-touch .leaflet-bar a:last-child {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom: none;
  }

  .leaflet-touch .leaflet-bar a {
    width: 32px;
    height: 32px;
    line-height: 32px;
  }
}

.leaflet-bar a,
.leaflet-bar a:hover {
  color: #555;
}

.leaflet-bar a:first-child {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.leaflet-bar a:last-child {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom: none;
}

#box-zoom-button::after {
  content: '';
  visibility: hidden;
  position: absolute;
  top: 5px;
  right: -174px;
  width: 160px;
  padding: 4px 8px;
  font-family: Work Sans, sans-serif;
  font-size: 0.625rem;
  font-weight: 500;
  line-height: 1.4em;
  word-wrap: break-word;
  color: #fff;
  border-radius: 4px;
  background-color: rgba(97, 97, 97, 0.9);
}

#box-zoom-button:hover::after {
  content: 'Selecione uma área para zoom';
  visibility: visible;
}

.locale-en #box-zoom-button:hover::after {
  content: 'Select zoom area';
}

.euiTitle {
  color: #404040;
}

.euiFacetButton__text{
  text-transform: capitalize;
}

.euiRangeLevel--primary{
  background-color: rgba(192, 58, 43, 0.8);
}

.euiRangeLevel--warning{
  background-color: rgba(199, 199, 199, 0.3);
}

.euiFieldSearch:focus {
  background-image: linear-gradient(to top, #c03a2b, #c03a2b 2px, transparent 2px, transparent 100%);
}
.euiFieldText:focus {
  background-image: linear-gradient(to top, #c03a2b, #c03a2b 2px, transparent 2px, transparent 100%);
}

.react-datepicker__day--keyboard-selected {
    border: solid 2px #c03a2b;
}
.react-datepicker__day--today {
  color: #c03a2b;
}
.react-datepicker__year-option--selected_year.react-datepicker__year-option--preselected{
  background-color: #c03a2b;
}
.react-datepicker__month-option--selected_month.react-datepicker__month-option--preselected{
  background-color: #c03a2b;
}
.euiFacetButton a {
  color: #c0392b;
}

.euiFacetButton--isSelected .euiNotificationBadge {
  background-color: #404040;
  color: #fff;
}

.euiFacetButton--unSelected .euiNotificationBadge {
  background-color: #808080;
  color: #fff;
}
