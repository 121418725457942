@import "../../../core/themes/plataformaAlerta/palette";

.header {
  background-color: white;
  width: 100%;
  padding: 5px 15px 5px 15px;
}

.headerButtonContent {
  height: 50px;
  background-color: $whiteColor;
}

footer {
  padding: 5px 15px 5px 15px;
  background-color: #E0E0E0;
  height: 30px;
  bottom: 0px;
  position: fixed;
  left: 0px;
  right: 0px;
  margin-bottom: 0px;
}

.loading {
  width: 100%;
  background-color: $whiteColor; //$blackColorThird;
}

.lost_connection {
  text-align: center;
}

.satellite {
  width: 40%;
  height: auto;
}